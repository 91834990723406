<template>
  <div class="dialog">
    <div class="dialog-header" v-if="showDialogHeader">
      <div class="row">
        <div class="col">
          <p class="dialog-title text-uppercase mb-0">Select School</p>
        </div>
      </div>
    </div>
    <div class="dialog-content"
         style="overflow-y: auto;">
      <div class="row" style="min-height: 60vh;">
        <div class="col">
          <school-widget @changed="getSchool"
                         ref="schoolWidget">
          </school-widget>
          
          <div class="school-details">
            <div class="row" v-if="school">
              <div class="col-4">
                <div class="school-logo-wrapper">
                  <img class="img-fluid school-logo"
                       :src="(school.logo_url) ? school.logo_url : defaultLogo"
                       alt="school-logo"
                       @load="isLoading = true" />
                </div>
              </div>
              <div class="col-8 align-self-center">
                <p class="medium mb-1">
                  <b> {{ school.school_name}} </b>
                </p>
                <p class="small mb-0" v-if="schoolAddress">
                  {{ schoolAddress }}
                </p>
                <p class="small mb-0"> {{ schoolCityCountryState }} </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Footer -->
      <div class="row">
        <div class="col">
          <div class="text-right">
            <b-button class="btn btn-success btn-h3-small"
                      type="submit"
                      :disabled="school === null"
                      @click="updateProfile">
              Submit
            </b-button>
            <b-button class="btn btn-danger btn-h3-small"
                      @click="closeModal">
              Cancel
            </b-button>
          </div>
        </div>
      </div>
    </div>

    <!-- Loading -->
    <loading :active.sync="loading"
             :is-full-page="true"
             background-color="#000"
             color="#068bcd"
             :opacity="0.5"
             :width="70"
             :height="70">
      <rainbow-loader></rainbow-loader>
    </loading>
  </div>
</template>

<script>
  import BreakpointMixin from '@/mixins/BreakpointMixin';
  import { mapActions } from 'vuex';

  const Loading = () => import('vue-loading-overlay');
  const RainbowLoader = () => import('@/components/shared/RainbowLoader');
  const SchoolWidget = () => import('@/components/shared/SchoolWidget.vue');

  export default {
    name   : 'SelectSchool',
    mixins : [
      BreakpointMixin,
    ],
    props : {
      cancelable : {
        type    : Boolean,
        default : true,
      },
      showDialogHeader : {
        type    : Boolean,
        default : true,
      },
    },
    data() {
      return {
        school      : null,
        defaultLogo : process.env.VUE_APP_ADMIN_BUCKET_BASE_URL +
          'school/default.png',
        loading : false,
      }
    },
    components : {
      Loading,
      RainbowLoader,
      SchoolWidget,
    },
    computed : {
      schoolAddress() {
        const address1 = (this.school.address_1) ? this.school.address_1 : '';
        const address2 = (this.school.address_2) ?
          ', ' + this.school.address_2 : '';

        return address1 + address2;
      },
      schoolCityCountryState() {
        const city = (this.school.city_name) ? this.school.city_name : '';
        const state = (this.school.state_name) ?
          ', ' + this.school.state_name : '';
        const country = (this.school.country_name) ?
          ', ' + this.school.country_name : '';

        return city + state + country;
      },
    },
    methods : {
      ...mapActions({
        'getUpdatedUser' : 'user/getUpdatedUser',
      }),

      /**
       * Set Selected School
       * @param schoolId
       */
      getSchool(schoolId) {
        this.school = null;
        if (schoolId) {
          this.$http.get('api/school', {
            params : {
              id : schoolId,
            },
          }).then(response => {
            this.school = response.data;
          }).catch(() => {
            this.$notify({
              group : 'notif',
              type  : 'error',
              title : 'Failed!',
              text  : 'Oops! Something went wrong!',
            });
          });
        }
      },

      /**
       * Close Modal
       */
      closeModal() {
        if (this.cancelable)
          this.$emit('close');
        else
          this.$emit('cancel-select-school');
      },

      /**
       * Update User Profile
       */
      updateProfile() {
        this.loading = true;
        this.$http.put('api/user', {
          'userId'       : this.$store.getters['user/user'].user_id,
          'updateFields' : {
            'school_id' : this.school.school_id,
          },
        }).then(() => {
          this.loading = false;
          this.getUpdatedUser();
          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success!',
            text  : 'Your profile has been updated!',
          });
          this.$emit('successful');
        }).catch(() => {
          this.loading = false;
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed!',
            text  : 'Oops! Something went wrong.',
          });
        });
      },

      /**
       * Reset Selected School
       */
      resetSelectedSchool() {
        this.$refs.schoolWidget.resetSelectedSchool();
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/components/modals/select-school";
</style>